import React, { useState, useCallback, useEffect } from 'react';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import './AudienceTargetModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import Dropdown from '../../../../../common/Dropdown/Dropdown/Dropdown';
import { isEmpty, startCase } from 'lodash';
import Checkbox from '../../../../../common/Checkbox/Checkbox';
import ClickableIcon from '../../../../../common/ClickableIcon/ClickableIcon';
import Slider from '../../../../../common/Slider/Slider';
import EstimatedReachWidget from '../../EstimatedReachWidget/EstimatedReachWidget';
import Separator from '../../../../../common/Separator/Separator';
import TabSelector from '../../../../../common/TabSelector/TabSelector';
import TextBox from '../../../../../common/TextBox/TextBox';
import * as MixpanelCommon from '../../../../../../infra/mixpanel/MixpanelCommon';
import { getChannelName } from '../../../../../../middleware/AngularExportedMiddleware';
import {
  getGeoFromAudienceSegment,
  getSummaryTextBuilder,
  SYNDICATION_VALUES,
} from '../../../../../../../data/audience-segment-builder-helper';
import { NUM_USERS_BY_GEO } from '../../../../../../services/AudienceInsightsService';
import { Radio, RadioGroup, Switch } from '@amobee/component-library';

const amplificationModes = [
  { label: 'Custom', value: 'custom' },
  { label: 'Always On', value: 'always_on' },
];
const optimizationOptions = [
  { label: 'Optimize Reach', value: 'REACH' },
  { label: 'Clicks', value: 'CLICKS' },
  { label: 'Actions', value: 'ACTIONS' },
];

const AudienceTargetModal = ({
  isOpen,
  onSubmitCustom,
  onSubmitAlwaysOn,
  onCancel,
  marketsAndAdvertisersPromise,
  audienceName,
  isProcessing,
  getSegmentIds,
  dspService,
  audienceSegment,
  channel,
  isAudienceDeterministicActivated,
  isAudienceAmplifiedActivated,
  isAudienceAlwaysOnActivated,
  activatedAmplifiedThreshold,
  activatedAlwaysOnThreshold,
  activatedMarket,
  activatedAdvertiser,
  activatedDataContractId,
  activatedDataContractText,
  activatedCategoryId,
  getAmplifiedEstimatedReachGoal,
  widgetName,
  hasAmplificationModeSelector,
  getDataContract,
  getTaxonomyCategory,
  isDeterministicDynamicAllowed,
  allowDeterministicSegment,
  allowCustomPriceSegment,
  allowOptimizeSegment,
}) => {
  const geo = getGeoFromAudienceSegment(audienceSegment);
  const bidstreamUsers = NUM_USERS_BY_GEO[geo] || NUM_USERS_BY_GEO.US;
  const modalTitle = 'Activate your audience';
  const amplifiedHelpDescription =
    'Audience size ranges from 1% to 20% of the targetable audience. A 1% amplification consists of the people most similar to your audience source. Increasing the percentage creates a bigger, broader audience.';
  const allAdvertisersOption = { value: -1, label: 'All advertisers', name: 'All advertisers' };
  const defaultReachGoal = { value: 2, label: 2 };
  const maxReachGoal = { value: 20, label: 20 };
  const isCustomAudienceActivated = isAudienceDeterministicActivated || isAudienceAmplifiedActivated;
  const [marketsPromise] = useState(
    activatedMarket ? Promise.resolve([activatedMarket]) : marketsAndAdvertisersPromise.then((res) => res.markets)
  );
  const [selectedMarket, setSelectedMarket] = useState(activatedMarket || {});
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(activatedAdvertiser || {});
  const [isSegmentOptimized, setIsSegmentOptimized] = useState(false);
  const [selectedOptimization, setSelectedOptimization] = useState(optimizationOptions[0].value);
  const [isAmplified, setIsAmplified] = useState(channel === 'articles');
  const [tremorSyndicateAmplified, setTremorSyndicateAmplified] = useState([]);
  const [tremorSyndicateDeterministic, setTremorSyndicateDeterministic] = useState([]);
  const [isCustomPriceAmplified, setIsCustomPriceAmplified] = useState(false);
  const [isCustomPriceDeterministic, setIsCustomPriceDeterministic] = useState(false);
  const [isDeterministic, setIsDeterministic] = useState(false);
  const [isDynamic, setIsDynamic] = useState(isDeterministicDynamicAllowed);
  const [amplifiedReachGoal, setAmplifiedReachGoal] = useState(
    activatedAmplifiedThreshold
      ? { value: activatedAmplifiedThreshold, label: activatedAmplifiedThreshold }
      : defaultReachGoal
  );
  const [alwaysOnReachGoal, setAlwaysOnReachGoal] = useState(
    activatedAlwaysOnThreshold
      ? { value: activatedAlwaysOnThreshold, label: activatedAlwaysOnThreshold }
      : defaultReachGoal
  );
  const [isMarketsPromiseLoading, setIsMarketsPromiseLoading] = useState(!isCustomAudienceActivated);
  const [isMarketsDropdownDisabled, setIsMarketsDropdownDisabled] = useState(true);
  const [advertisersPromise, setAdvertisersPromise] = useState(
    activatedAdvertiser
      ? Promise.resolve([activatedAdvertiser])
      : marketsAndAdvertisersPromise.then((res) => [
          ...(res.advertisers.length > 0 ? [allAdvertisersOption] : []),
          ...res.advertisers,
        ])
  );
  const [isEmptyAdvertisers, setIsEmptyAdvertisers] = useState(true);
  const reachGoalValues = range(1, 21).map((x) => ({
    value: x,
    label: x % 2 == 0 ? `${x}%` : '',
  }));
  const [selectedAmplificationModeTab, setselectedAmplificationModeTab] = useState(amplificationModes[0]);
  const [currentAmplificationMode, setAmplificationMode] = useState(amplificationModes[0].value);
  const onSelectAmplificationMode = (selectedAmplificationMode) => {
    setselectedAmplificationModeTab(selectedAmplificationMode);
    const mode = selectedAmplificationMode.value;
    setAmplificationMode(mode);
    if (mode === 'always_on') {
      setIsAmplified(true);
    } else {
      setIsAmplified(channel === 'articles');
      setIsDeterministic(false);
    }
  };
  const [dataContractId, setDataContractId] = useState(activatedDataContractId || '');
  const [dataContractText, setDataContractText] = useState(activatedDataContractText);
  const [dataContractDisp, setDataContractDisp] = useState(
    activatedDataContractText
      ? `${activatedDataContractId} - ${activatedDataContractText}`
      : activatedDataContractId
      ? `${activatedDataContractId}`
      : ''
  );
  const [dataContractIdErrorMessage, setDataContractIdErrorMessage] = useState('');
  const [categoryId, setCategoryId] = useState(activatedCategoryId ? `${activatedCategoryId}` : '');
  const [categoryExists, setCategoryExists] = useState(isAudienceAlwaysOnActivated);
  const [categoryIdErrorMessage, setCategoryIdErrorMessage] = useState('');
  const [lastCheckedDataContractId, setLastCheckedDataContractId] = useState('');
  const [lastReturnedDataContractText, setLastReturnedDataContractText] = useState('');
  const [lastCheckedCategoryId, setLastCheckedCategoryId] = useState('');
  const [lastCategoryExistsResult, setLastCategoryExistsResult] = useState(false);

  const onMarketChange = useCallback(
    (newMarket) => {
      setSelectedMarket(newMarket);
      setAdvertisersPromise(
        dspService.getAmplificationAdvertiserContext(newMarket.value, 'value').then(({ advertisers }) => {
          advertisers.length > 0 ? setSelectedAdvertiser(allAdvertisersOption) : setSelectedAdvertiser(null);
          setIsEmptyAdvertisers(advertisers.length == 0);
          return [...(advertisers.length > 0 ? [allAdvertisersOption] : []), ...advertisers];
        })
      );
    },
    [selectedMarket]
  );

  const onAdvertiserChange = useCallback(
    (newAdvertiser) => {
      setSelectedAdvertiser(newAdvertiser);
    },
    [selectedAdvertiser]
  );

  const onIsAmplifiedClicked = useCallback(
    (newIsAmplified) => {
      setIsAmplified(newIsAmplified);
      setAmplifiedReachGoal(defaultReachGoal);
    },
    [isAmplified, amplifiedReachGoal]
  );

  const onIsDeterministicClicked = useCallback(
    (newIsDeterministic) => {
      setIsDeterministic(newIsDeterministic);
    },
    [isDeterministic]
  );

  const onIsDynamicClicked = useCallback(
    (newIsDynamic) => {
      setIsDynamic(newIsDynamic);
    },
    [isDynamic]
  );

  const onAmplifiedReachGoalChanged = useCallback(
    (newAmplifiedReachGoal) => {
      setAmplifiedReachGoal(newAmplifiedReachGoal);
    },
    [amplifiedReachGoal]
  );

  const onAlwaysOnReachGoalChanged = useCallback(
    (newAlwaysOnReachGoal) => {
      setAlwaysOnReachGoal(newAlwaysOnReachGoal);
    },
    [alwaysOnReachGoal]
  );

  function getDataContractDetails() {
    if (!dataContractId) return;
    getDataContract(dataContractId).then((res) => {
      if (res.error) {
        setDataContractText('');
        setLastReturnedDataContractText('');
        setDataContractIdErrorMessage(res.error);
        setDataContractDisp(dataContractId);
      } else {
        setLastReturnedDataContractText(res.name);
        setDataContractText(res.name);
        setDataContractDisp(`${dataContractId} - ${res.name}`);
      }
    });
    getCategoryDetails();
  }

  function getCategoryDetails() {
    if (categoryId) {
      getTaxonomyCategory(dataContractId, categoryId).then((res) => {
        const taxonomyCategoryExists = parseInt(res) !== -1;
        setCategoryExists(taxonomyCategoryExists);
        setLastCategoryExistsResult(taxonomyCategoryExists);
        setCategoryIdErrorMessage(
          taxonomyCategoryExists ? '' : 'Category ID not found. Double check the ID and try again.'
        );
      });
    }
  }

  const onDataContractChange = useCallback(
    ({ target: { value } }) => {
      setDataContractId(value || '');
      setDataContractDisp(value || '');
      setDataContractText('');
    },
    [setDataContractId, setDataContractDisp]
  );

  const onDataContractFocus = useCallback(() => {
    setDataContractDisp(dataContractId);
  }, [setDataContractDisp, dataContractId]);

  const onDataContractBlur = useCallback(() => {
    if (dataContractId === lastCheckedDataContractId) {
      setDataContractText(lastReturnedDataContractText);
      setDataContractDisp(
        lastReturnedDataContractText ? `${dataContractId} - ${lastReturnedDataContractText}` : dataContractId
      );
      return;
    }
    setDataContractIdErrorMessage('');
    setLastCheckedDataContractId(dataContractId);
    getDataContractDetails();
  }, [
    setDataContractDisp,
    dataContractId,
    dataContractText,
    lastCheckedDataContractId,
    lastReturnedDataContractText,
    setLastCheckedDataContractId,
    setDataContractText,
    setDataContractIdErrorMessage,
    categoryId,
    setCategoryExists,
    setCategoryIdErrorMessage,
  ]);

  const onCategoryChange = useCallback(
    ({ target: { value } }) => {
      setCategoryExists(false);
      setCategoryId(value || '');
    },
    [setCategoryId]
  );

  const onCategoryBlur = useCallback(() => {
    if (categoryId === lastCheckedCategoryId) {
      setCategoryExists(lastCategoryExistsResult);
      return;
    }
    setLastCheckedCategoryId(categoryId);
    setCategoryIdErrorMessage('');
    getCategoryDetails();
  }, [
    categoryId,
    lastCheckedCategoryId,
    lastCategoryExistsResult,
    setLastCheckedCategoryId,
    dataContractId,
    setCategoryExists,
    setCategoryIdErrorMessage,
  ]);

  useEffect(() => {
    (async () => {
      if (isCustomAudienceActivated || !marketsAndAdvertisersPromise) return;
      const { markets, advertisers } = await marketsAndAdvertisersPromise;
      setSelectedMarket(markets[0]);
      if (advertisers.length > 0) setSelectedAdvertiser(allAdvertisersOption);
      setIsMarketsDropdownDisabled(markets.length <= 1);
      setIsEmptyAdvertisers(advertisers.length == 0);
      setIsMarketsPromiseLoading(false);
    })();
  }, [marketsAndAdvertisersPromise]);

  const marketsSummaryTextBuilder = (markets) => (markets.length === 0 ? 'No available markets' : markets[0].label);
  const advertisersSummaryTextBuilder = (advertisers) =>
    advertisers.length === 0 ? 'No available advertisers' : advertisers[0].label;

  const mixpanelTrackTargetSubmit = () => {
    // Mixpanel block. Dynamically get the app location from location hash for correct Mixpanel track info variety
    const hashString = location && location.hash ? location.hash.split('/')[1] : '';
    const appLocation = startCase(hashString).split(' ').join('-') || 'Audience';
    const props = {
      'Dynamically update segment': isDynamic,
      'Audience Name': audienceName,
      'was amplified': isAmplified && currentAmplificationMode === 'custom',
      'always on amplified': currentAmplificationMode === 'always_on',
      ...(currentAmplificationMode === 'custom' && { advertiser: selectedAdvertiser.label }),
      appName: 'Audience',
      channel: getChannelName(channel),
      ...(currentAmplificationMode === 'custom' && { market: selectedMarket.label }),
      widget: widgetName || hashString,
      ...(isAmplified && {
        'Estimated Reach': getAmplifiedEstimatedReachGoal(bidstreamUsers, amplifiedReachGoal.value),
      }),
      ...(isAmplified && { 'segment type': audienceSegment.label }),
      ...(isAmplified && { 'scale number': amplifiedReachGoal.label }),
      ...(isAmplified && { 'deterministic activation': isAudienceDeterministicActivated }),
      ...(currentAmplificationMode === 'always_on' && { data_contract_id: dataContractId }),
      ...(currentAmplificationMode === 'always_on' && { category_id: categoryId }),
    };
    MixpanelCommon.sendToMixpanel(`${appLocation} - Activated Audience Settings`, props);
  };

  return (
    <div className="audience-target-modal-component">
      <ModalWithConfirmationButtons
        width="557px"
        modalTitle={modalTitle}
        isOpen={isOpen || isProcessing}
        isCancelDisabled={isProcessing}
        isSubmitDisabled={
          isProcessing ||
          (currentAmplificationMode === 'always_on' &&
            (isAudienceAlwaysOnActivated || !dataContractText || !categoryExists)) ||
          (currentAmplificationMode === 'custom' &&
            (!(isDeterministic || isAmplified) ||
              (isAudienceAmplifiedActivated && (isAudienceDeterministicActivated || channel === 'articles')) ||
              isEmpty(selectedMarket) ||
              isEmpty(selectedAdvertiser) ||
              isMarketsPromiseLoading))
        }
        maxHeightBeforeScroll="550px"
        submitLabel={'Activate'}
        onSubmit={() => {
          mixpanelTrackTargetSubmit();
          currentAmplificationMode === 'custom' &&
            onSubmitCustom(
              selectedMarket,
              selectedAdvertiser,
              isDeterministic,
              isAmplified,
              amplifiedReachGoal,
              isDynamic,
              tremorSyndicateAmplified,
              tremorSyndicateDeterministic,
              isCustomPriceAmplified,
              isCustomPriceDeterministic,
              selectedOptimization
            );
          currentAmplificationMode === 'always_on' &&
            onSubmitAlwaysOn(parseInt(dataContractId), dataContractText, parseInt(categoryId), alwaysOnReachGoal);
        }}
        onCancel={() => onCancel()}
        showSpinner={isProcessing}
        submitButtonTooltip={'Once you activate your audience, it cannot be edited.'}
        titleMargin={'0 0 16px 0'}
        isLightTheme={true}
        isDisabledEnterKeyPress={
          isProcessing ||
          isEmpty(selectedMarket) ||
          isEmpty(selectedAdvertiser) ||
          !(isDeterministic || isAmplified) ||
          isMarketsPromiseLoading
        }
      >
        <div className="audience-target-modal-content">
          <div className="audience-name-label">
            <span className="label">Audience Name:</span>
            <span className="audience-name-text">{audienceName}</span>
          </div>

          {hasAmplificationModeSelector && (
            <div className="amplification-selector">
              <TabSelector
                tabs={amplificationModes}
                selectedTab={selectedAmplificationModeTab}
                onSelect={onSelectAmplificationMode}
                isDarkTheme={false}
                tabLocation={'start'}
              ></TabSelector>
            </div>
          )}

          {currentAmplificationMode === 'custom' && <div className="label">Activate to market:</div>}
          {currentAmplificationMode === 'custom' && (
            <div className="market-dropdown">
              <Dropdown
                promise={marketsPromise}
                selectedValue={selectedMarket}
                isMulti={false}
                isSearchable={true}
                onSelect={onMarketChange}
                shouldCloseOnSelect={true}
                shouldCloseOnClickOutside={true}
                summaryTextBuilder={marketsSummaryTextBuilder}
                isDisabled={isCustomAudienceActivated || isMarketsDropdownDisabled}
                isLightTheme={true}
              />
            </div>
          )}
          {currentAmplificationMode === 'custom' && (
            <div className="advertiser-dropdown">
              <Dropdown
                promise={advertisersPromise}
                selectedValue={selectedAdvertiser}
                isMulti={false}
                isSearchable={true}
                onSelect={onAdvertiserChange}
                shouldCloseOnSelect={true}
                shouldCloseOnClickOutside={true}
                summaryTextBuilder={advertisersSummaryTextBuilder}
                isDisabled={isCustomAudienceActivated || isEmptyAdvertisers}
                isLightTheme={true}
              />
            </div>
          )}
          {currentAmplificationMode === 'always_on' && (
            <div className="data-contract-input">
              <div className="dropdown-label">Data Contract ID:</div>
              <TextBox
                value={dataContractDisp}
                placeholder="Add Data Contract ID"
                isDarkTheme={false}
                shouldDisplayErrorMessages={true}
                errorMessage={dataContractIdErrorMessage}
                onChange={onDataContractChange}
                onFocus={onDataContractFocus}
                onBlur={onDataContractBlur}
                isDisabled={isAudienceAlwaysOnActivated}
              ></TextBox>
            </div>
          )}
          {currentAmplificationMode === 'always_on' && (
            <div className="category-input">
              <div className="dropdown-label">Category ID:</div>
              <TextBox
                value={categoryId}
                placeholder="Add Category ID"
                isDarkTheme={false}
                shouldDisplayErrorMessages={true}
                errorMessage={categoryIdErrorMessage}
                onChange={onCategoryChange}
                onBlur={onCategoryBlur}
                isDisabled={isAudienceAlwaysOnActivated}
              ></TextBox>
            </div>
          )}
          {channel !== 'articles' && currentAmplificationMode === 'custom' && (
            <div className="checkbox-row">
              <Checkbox
                key={isAmplified}
                label={'Amplify your audience with a lookalike model'}
                onToggle={(e) => onIsAmplifiedClicked(e)}
                isSelected={isAmplified}
                isDisabled={isAudienceAmplifiedActivated}
              ></Checkbox>
              {isAudienceAmplifiedActivated && (
                <ClickableIcon
                  className={'activation-disabled-info'}
                  iconId={'info'}
                  tooltip={'This audience has been amplified and therefore cannot be amplified again.'}
                />
              )}
            </div>
          )}
          {isAmplified && (
            <div className="amplified-part">
              <div className="checkbox-row audience-size-header">
                <div className="label">Audience Size</div>
                <ClickableIcon
                  className={'activation-disabled-info amplified-help-description'}
                  iconId={'info'}
                  tooltip={amplifiedHelpDescription}
                />
                {((isAudienceAmplifiedActivated && currentAmplificationMode == 'custom') ||
                  (isAudienceAlwaysOnActivated && currentAmplificationMode == 'always_on')) && (
                  <ClickableIcon
                    className={'activation-disabled-info'}
                    iconId={'info'}
                    tooltip={'This audience has been amplified and therefore cannot be amplified again.'}
                  />
                )}
              </div>
              <div className="reach-goal-wrapper">
                {currentAmplificationMode === 'custom' && (
                  <Slider
                    values={reachGoalValues}
                    selectedValue={amplifiedReachGoal}
                    onChange={onAmplifiedReachGoalChanged}
                    isDisabled={isAudienceAmplifiedActivated || isSegmentOptimized}
                  />
                )}
                {currentAmplificationMode === 'always_on' && (
                  <Slider
                    values={reachGoalValues}
                    selectedValue={alwaysOnReachGoal}
                    onChange={onAlwaysOnReachGoalChanged}
                    isDisabled={isAudienceAlwaysOnActivated || isSegmentOptimized}
                  />
                )}
              </div>
              {allowOptimizeSegment && (
                <div className="checkbox-row">
                  <Switch
                    checked={isSegmentOptimized}
                    onChange={(e) => {
                      setIsSegmentOptimized(e.target.checked);
                      setSelectedOptimization(
                        e.target.checked ? optimizationOptions[1].value : optimizationOptions[0].value
                      );
                      setAmplifiedReachGoal(maxReachGoal);
                    }}
                  />
                  <span>Segment Optimization</span>
                  <RadioGroup
                    disabled
                    inline
                    onChange={(e) => setSelectedOptimization(e.currentTarget.value)}
                    selectedValue={selectedOptimization}
                  >
                    <Radio disabled={!isSegmentOptimized} value={optimizationOptions[1].value}>
                      {optimizationOptions[1].label}
                    </Radio>
                    <Radio disabled={!isSegmentOptimized} value={optimizationOptions[2].value}>
                      {optimizationOptions[2].label}
                    </Radio>
                  </RadioGroup>
                </div>
              )}
              <div className="checkbox-row">
                {currentAmplificationMode === 'custom' && allowCustomPriceSegment && (
                  <Checkbox
                    key={isCustomPriceAmplified}
                    label={'Custom segment'}
                    onToggle={setIsCustomPriceAmplified}
                    isSelected={isCustomPriceAmplified}
                  ></Checkbox>
                )}
              </div>
              <div className="row-label">Syndicate To:</div>
              <div className="madie-provider-dropdown">
                {currentAmplificationMode === 'custom' && (
                  <Dropdown
                    isMulti
                    values={SYNDICATION_VALUES}
                    selectedValues={tremorSyndicateAmplified}
                    summaryTextBuilder={getSummaryTextBuilder('All media providers')}
                    onSelect={setTremorSyndicateAmplified}
                  />
                )}
              </div>
              {currentAmplificationMode === 'custom' && (
                <EstimatedReachWidget
                  getReachNumber={() =>
                    Promise.all([
                      marketsAndAdvertisersPromise,
                      getAmplifiedEstimatedReachGoal(bidstreamUsers, amplifiedReachGoal.value),
                    ]).then((values) => values[1])
                  }
                  params={[amplifiedReachGoal.value]}
                  reachType={'users'}
                />
              )}
              {currentAmplificationMode === 'always_on' && (
                <EstimatedReachWidget
                  getReachNumber={() =>
                    Promise.all([
                      marketsAndAdvertisersPromise,
                      getAmplifiedEstimatedReachGoal(bidstreamUsers, alwaysOnReachGoal.value),
                    ]).then((values) => values[1])
                  }
                  params={[alwaysOnReachGoal.value]}
                  reachType={'users'}
                />
              )}
              {channel !== 'articles' && currentAmplificationMode === 'custom' && (
                <Separator className="content-separator" />
              )}
            </div>
          )}
          {channel !== 'articles' && currentAmplificationMode === 'custom' && allowDeterministicSegment && (
            <div className="checkbox-row">
              <Checkbox
                key={isDeterministic}
                label={'Create a deterministic segment based on your defined audience'}
                onToggle={onIsDeterministicClicked}
                isSelected={isDeterministic}
                isDisabled={isAudienceDeterministicActivated}
              ></Checkbox>
              {isAudienceDeterministicActivated && (
                <ClickableIcon
                  className={'activation-disabled-info'}
                  iconId={'info'}
                  tooltip={'This audience has been targeted and therefore cannot be targeted again.'}
                />
              )}
            </div>
          )}
          {channel !== 'articles' && currentAmplificationMode === 'custom' && isDeterministic && (
            <div className="deterministic-part">
              {isDeterministicDynamicAllowed && (
                <div className="checkbox-row">
                  <Checkbox
                    key={isDynamic}
                    label={'Dynamically update segment'}
                    onToggle={onIsDynamicClicked}
                    isSelected={isDynamic}
                  ></Checkbox>
                </div>
              )}
              {allowCustomPriceSegment && (
                <div className="checkbox-row">
                  <Checkbox
                    key={isCustomPriceDeterministic}
                    label={'Custom segment'}
                    onToggle={setIsCustomPriceDeterministic}
                    isSelected={isCustomPriceDeterministic}
                  ></Checkbox>
                </div>
              )}
              <div className="row-label">Syndicate To:</div>
              <div className="madie-provider-dropdown">
                <Dropdown
                  isMulti
                  values={SYNDICATION_VALUES}
                  selectedValues={tremorSyndicateDeterministic}
                  summaryTextBuilder={getSummaryTextBuilder('All media providers')}
                  onSelect={setTremorSyndicateDeterministic}
                />
              </div>
              <EstimatedReachWidget
                getReachNumber={() => getSegmentIds(audienceSegment, channel)}
                params={[audienceSegment, channel]}
                reachType={'households'}
              />
            </div>
          )}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

AudienceTargetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmitCustom: PropTypes.func.isRequired,
  onSubmitAlwaysOn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dspService: PropTypes.object.isRequired,
  marketsAndAdvertisersPromise: PropTypes.object.isRequired,
  audienceName: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool,
  getSegmentIds: PropTypes.func.isRequired,
  audienceSegment: PropTypes.array.isRequired,
  channel: PropTypes.string.isRequired,
  isAudienceDeterministicActivated: PropTypes.bool,
  isAudienceAmplifiedActivated: PropTypes.bool,
  isAudienceAlwaysOnActivated: PropTypes.bool,
  activatedAmplifiedThreshold: PropTypes.number,
  activatedAlwaysOnThreshold: PropTypes.number,
  activatedMarket: PropTypes.object,
  activatedAdvertiser: PropTypes.object,
  activatedDataContractId: PropTypes.number,
  activatedDataContractText: PropTypes.string,
  activatedCategoryId: PropTypes.number,
  getAmplifiedEstimatedReachGoal: PropTypes.func.isRequired,
  widgetName: PropTypes.string,
  hasAmplificationModeSelector: PropTypes.bool,
  getDataContract: PropTypes.func,
  getTaxonomyCategory: PropTypes.func,
  isDeterministicDynamicAllowed: PropTypes.bool,
  allowDeterministicSegment: PropTypes.bool,
  allowCustomPriceSegment: PropTypes.bool,
  allowOptimizeSegment: PropTypes.bool,
};

AudienceTargetModal.defaultProps = {
  isDeterministicDynamicAllowed: true,
};

export default AudienceTargetModal;
