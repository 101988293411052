import 'angular-modal-service';
import * as MixpanelSettings from '../../react/infra/mixpanel/MixpanelSettings';

angular.module(__filename, [
    require('data/settings/program-service').name,
    require('data/keyword_suggester.srv.js').name,
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
    'angularModalService',
    require('./share-program/share-program').name,
    require('./program_settings/program-settings').name,
    require('./clone-program/clone-program').name,
]).controller('ProgramsController', ['$timeout', '$scope', 'programService', 'ModalService', 'confirmAction',
                                     'abiPermissions', 'util',
    function ($timeout, $scope, programInterface, ModalService, confirmAction, abiPermissions, util) {
        populate();
        $scope.deleteProgram = deleteProgram;
        $scope.cloneProgram = cloneProgram;
        $scope.shareProgram = shareProgram;
        $scope.removeProgram = removeProgram;
        $scope.editProgram = editProgram;
        $scope.isMyInterests = util.isMyInterestsProgram;
        $scope.isMySegments = util.isMySegmentsProgram;
        $scope.hasPermissionForMySegments = abiPermissions.hasPermission(['my segments program', 'first party segments']);
        $scope.programsSortPred = programsSortPred;
        $scope.animateSize = util.animateElementBloat;
        $scope.confirmationNeeded = false;
        $scope.isProgramOwner = isProgramOwner;
        $scope.isMyProgramType = (program) => $scope.isMyInterests(program) || $scope.isMySegments(program);

        $scope.$on('new-program', populate);

        /////////////////////////////////////////////
        MixpanelSettings.trackPageView('programs');
        function populate() {
            programInterface.userProgramsList($scope.$root.user.id).then(function (data) {
                $scope.programs = _.filter(data, (prog) => $scope.hasPermissionForMySegments || !$scope.isMySegments(prog));
                $scope.gotData = true;
            });
        }

        function cloneProgram(program) {
            ModalService.showModal({
                template: require('./clone-program/clone-program.html'),
                inputs: {
                    program: program
                },
                controller: 'cloneProgramController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result != undefined && result.reload) populate();
                });
            });
        }

        function shareProgram(program) {
            ModalService.showModal({
                template: require('./share-program/share-program.html'),
                inputs: {
                    program: program
                },
                controller: 'shareProgramController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result != undefined && result.reload) populate();
                });
            });
        }

        function removeProgram(id) {
            var idMap = $scope.programs.map(function (p) {
                return p.id;
            });
            var programInx = idMap.indexOf(id);

            var confirmationMsg = "Are you sure you want to remove the program " + $scope.programs[programInx].name + " from your programs?";
            confirmAction.getConfirmation(confirmationMsg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) executeRemove(id);
                });
            });

            function executeRemove(id) {
                programInterface.remove(id).then(function () {
                    removeFromList(id, programInx);
                });
            }
        }

        function deleteProgram(id) {
            var idMap = $scope.programs.map(function (p) {
                return p.id;
            });
            var programInx = idMap.indexOf(id);

            var confirmationMsg = "Are you sure you want to delete the program " + $scope.programs[programInx].name + "?";
            var txtConfirm = 'Yes';
            var txtCancel = 'No';
            if ($scope.programs[programInx]['shared?']) {
                confirmationMsg = "This program is shared by other users.\nIf you delete it, they will lose it as well.\nAre you sure you want to delete this program?";
                txtConfirm = "Yes, I'm sure";
                txtCancel = 'No, keep it';
            }

            confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) executeDelete(id);
                });
            });

            function executeDelete(id) {
                programInterface.deleteProgram(id).then(function () {
                    removeFromList(id, programInx);
                });
            }
        }

        function removeFromList(id, index) {
            $scope.programs.splice(index, 1);
            $scope.$root.$broadcast('program-removed', id);
        }

        function editProgram(program) {
            if (program == undefined) {
                OpenEditProgramModal({competitors: [], sensitive_content: abiPermissions.hasPermission("Include sensitive content by default")});
            } else {
                programInterface.program(program.id).then(function (program) {
                    OpenEditProgramModal(program);
                });
            }
        }

        function OpenEditProgramModal(program) {
            ModalService.showModal({
                template: require('./program_settings/program-settings.html'),
                inputs: {program: program, initialView: null},
                controller: 'programSettingsController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result != undefined && result.reload) populate();
                });
            });
        }

        function programsSortPred(program) {
            return !util.isMyInterestsProgram(program) * 2 + !util.isMySegmentsProgram(program) * 1;
        }

        function isProgramOwner(program) {
            return $scope.$root.user.id == program.creator.id;
        }
    }
]).stateConfig = {
    name: 'programs',
    url: '/programs',
    template: require('./programs_index.html'),
    controller: 'ProgramsController',
    display: 'Settings',
    data: {
      permissions: []
    }
};

module.exports = angular.module(__filename);
