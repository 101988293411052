import { getUserAllActivatedSegments } from '../../react/services/AudienceService.js';

angular.module(__filename, []).controller('ActivatedAudienceSegmentsController',
    ['$scope', 'util',
        function ($scope, util) {

            const populateSegmentsTable = async () => {
                const {segments} = await getUserAllActivatedSegments($scope.$root.user.id);
                $scope.segments = segments;
                $scope.gotData = true;
                $scope.$apply();
            }

            populateSegmentsTable();

            $scope.programText = function (program) {
                if(util.isMyInterestsProgram(program)) return 'My interests';
                return util.isMySegmentsProgram(program) ? 'My segments' : program.name;
            };

        }
    ]).stateConfig = {
    name: 'activated_audience_segments',
    url: '/activated_audience_segments',
    template: require('./activated_audience_segments.html'),
    controller: 'ActivatedAudienceSegmentsController',
    data: {
        permissions: []
    },
    display: 'Activated Audience Segments'
};

module.exports = angular.module(__filename);
